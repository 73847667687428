/* CUSTOM CSS */
/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100vh;
    margin: 0px;
}
body {
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif !important;
    background: #ffffff;
    padding: 0px;
    margin: 0px;
}

#logo {
    height: 50px;
}
.outer-container {
    margin: 0px;
    padding: 0px;
}
.inline-div { display: inline; }
.login-block {
    float: right;
    margin-top: 1em;
    margin-right: .5em;
    background-color: #eeeeee;
    border-color: #ffffff;
    border-radius: .3em;
    padding: .3em;

}

.portal-header {
    margin-left: 1em;
    font-size: 1.5em;
    color: #ffffff;
    font-weight: strong;
}
.main {
    position: relative;
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
    padding-top: 1em;
}

.main-header {
    width: 100%;
    height: 95px;
    background: url(https://relentlessly-positive.com/wordpress/wp-content/uploads/2019/07/footer-bg-2-1200x480.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 0px;
}

.footer {
    clear: both;
    text-align: right;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #eeeeee;
    margin: 0px;

}

.logo-container {
    transition: all .15s; 
    padding: 1em;
    height: 75px;
}

.logo-container-open {
    transition: all .15s; 
    margin-left: 240px;
    padding: 1em;
    height: 75px;
}
.content-container {
    padding: 1em;
}
.the-body { 
    position: relative; 
    min-height: 100%;
}
.f-right { float:right; }
.f-left { float:left; }

.small-boxart { width: 60px; height: 75px; }

.bg-jettct {
    background-color: #2c5697 !important;
    background: url(https://relentlessly-positive.com/wordpress/wp-content/uploads/2019/07/footer-bg-2-1200x480.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 100%;
    text-align: center;
}
.bg-jettct .login-banner {
    padding-top: 2.5em;
    width: 100%;
    min-height: 100%;

}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body>.container {
    padding: 0px; 
}


code {
    font-size: 80%;
}

.App {
    display: flex;
    width: 100%;
    min-height: 100vh;
    align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}
.my-icon { font-size: 1.25em; }
.item-listing {
    border: 1px solid #888;
    background-color: #ffffff;
    padding: .5em;
}
.item-listing:hover {
    border: 1px solid blue;
    background-color: #eeeeee;
    cursor: pointer;
}
.item-icon {
    float: left;
    padding: 5px;
    font-size: 1.5em;
    margin-right: 1em;
    text-align: center;
    border: 1px solid #aaa;
}
.item-description {
    float: right;
}
.video-tabs {
    border-bottom: 1px solid #777;
}
.video-tab {
    border-left: 1px solid #777;
    border-top: 1px solid #777;
    border-right: 1px solid #777;
    border-radius: 5px 5px 0 0;
}
.video-tabs .active {
    background: #aaa;
}
.pad5 { margin-left: 1em; }
.padded { padding: 1em; }
.padded-top { margin-top: 1em; }

.sidenav---sidenav---_2tBP {
    background-color: #e87722 !important;
}
.card .card-header {
    background-color: #2c5697 !important;
    color: #ffffff;
}
.padded5 { padding: 5px; }
.smaller { font-size: .85em; }
.padded-top { margin-top: 1em; }
.padded-right { margin-right: 5px;}
.clear-both { clear: both; }
.pam-sig { height: 2.5em; margin-top: .5em; margin-left: .5em; }
.headshot { padding: 0px 1em 1em 0em; width: 250px; }