

/* personIcon with Credit Tier and Label */

.applicant_list {
	display: flex;
	flex-direction: column;

}

.applicantCard {
	display: flex;
	flex-direction: row;
	border: solid thin #f3f3f3;
	// background: #FFFFFF;
	margin-bottom: 5px;
}

.personBadge {
	flex-grow: 3;
	background: url('/assets/img/custom/person.wBadgeAndLabel.svg') no-repeat top left;
	background-size: contain;
	padding: 2em;
	max-width: 88px;
}

.applicantInfo {
	flex: 9;
	padding: 0.5rem;

}

.applicantContactInfo {


}


// Person Badge

// Checklist
.fa-check-circle { font-size: 2rem; margin: 5px .4rem 0; }
.fa-square-o { font-size: 1.5rem; color: silver; margin: 5px .7rem 0; }
.stipItem { border-bottom: thin solid silver; padding: .5rem 0; display: flex; align-items: center; justify-content: space-between;}
.stipName {line-height: 1rem; padding-right: 3rem; text-align: left; padding-left: 1rem;}
.stipStatus { right: 0;}
.noPadding { padding: 0; }

// File Upload
.file-upload { border: 0; }
.dzu-dropzone-custom { border: 3px dashed #c8ced3;}